import { Form, Input, Table } from "Components";
import React, { useCallback, useMemo, useState } from "react";
import styles from "../Inventory.module.scss";
// import ReviewConfirm from '../../Bookings/components/ReviewConfirm'
// import SuccessfulModal from '../../Bookings/components/Successful'
import { apiRequest } from "Services";
import { useQuery } from "react-query";
// import { selectOpts } from 'utils'
import { toast } from "react-toastify";
import { useBranches } from "hooks";
import { closeModal } from "store/slices/modalSlice";
import { useDispatch } from "react-redux";
import { BiPlus } from "react-icons/bi";
import { Header } from "Partial/Header";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router";
import FilterByList from "Components/Button/FilterByList";

const Drop = ({
  placeholder,
  options,
  onchange,
  idKey = "id",
  labelKey = "name",
  name,
}) => {
  return (
    <select className="" name={name} onChange={onchange}>
      <option value="">{placeholder}</option>
      {options?.map((x) => (
        <option value={x[idKey]}>{x[labelKey]}</option>
      ))}
    </select>
  );
};

const RequestInvTransfer2 = ({ state = 0 }) => {
  const dispatch = useDispatch();
  const { branches } = useBranches();
  const { data: itemsQuantity } = useQuery("/reports/inventory-items");

  const [formValues, setFormValues] = useState({});
  // const [, setItem] = useState()
  // const [, setUnit] = useState()
  // const [, setDetails] = useState({})
  const { data: items } = useQuery(`/admin/inventory-items`);
  const [isLoading, setLoading] = useState(false);

  const [entries, setEntries] = useState([]);

  const { data: all_source } = useQuery(`/admin/inventory-source`);
  const { data: source_from } = useQuery(
    `/admin/branch/${formValues?.branch_id_from}/inventory-source`,
    { enabled: Boolean(formValues?.branch_id_from) }
  );
  const { data: source_to } = useQuery(
    `/admin/branch/${formValues?.branch_id_to}/inventory-source`,
    { enabled: Boolean(formValues?.branch_id_to) }
  );

  const getSourceByBranch = useCallback(
    (branch_id) => {
      // console.log(branch_id)
      if (branch_id) {
        const list = all_source?.data?.filter(
          (source) => source?.branch?.id === parseInt(branch_id)
        );
        return list;
      }
      return [];
    },
    [entries, all_source?.data]
  );

  const handleChange = (e) => {
    setFormValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  // useEffect(() => {
  //     if (formValues?.boh) {
  //         setFormValues(prev => ({ ...prev, 'moh': prev.boh * 10 }))
  //     }
  //     //get item unit  by from source type of ingedient unit
  //     if (formValues?.inventory_item_id && formValues?.from_inventory_source) {
  //         const list = items?.data
  //         const ingredient = list?.find(item => item?.id === parseInt(formValues?.inventory_item_id))
  //         setItem(ingredient)
  //         const source_from = all_source?.data?.find(source => source?.id === parseInt(formValues?.from_inventory_source))
  //         const source_to = all_source?.data?.find(source => source?.id === parseInt(formValues?.to_inventory_source))
  //         setDetails(prev => ({ ...prev, source_from, source_to }))

  //         if (source_from?.type === 'moh') setUnit(ingredient?.moh_unit?.short_name)
  //         if (source_from?.type === 'boh') setUnit(ingredient?.boh_unit?.short_name)
  //     }
  // }, [formValues, all_source, items?.data])
  const navigate = useNavigate();
  const addEntry = () => {
    const id = Date.now();
    setEntries((prev) => [
      ...prev,
      {
        id,
        inventory_item_id: null,
        from_branch: "",
        from_inventory_source: "",
        amount: "",
        sending_unit: "",
        to_branch: "",
        to_inventory_source: "",
        receiving_qty: "",
        receiving_unit: "",
      },
    ]);
  };
  const deleteEntry = (id) =>
    setEntries((prev) => prev.filter((x) => x.id !== id));

  const getUnit = useCallback(
    (id) => {
      return items?.data?.find((item) => item?.id?.toString() === id)?.boh_unit
        ?.short_name;
    },
    [items?.data]
  );
  const getStatus = useCallback(
    (item_id, source_id, amount) => {
      if (source_id && item_id) {
        const item = items?.data?.find(
          (item) => item?.id?.toString() === item_id
        );
        const source = all_source?.data?.find(
          (source) => source?.id?.toString() === source_id
        );
        const relation = itemsQuantity?.data?.items?.find(
          (itemsQuantity) =>
            itemsQuantity?.display_name === item?.name &&
            itemsQuantity?.house_name === source?.name
        );
        if (relation) {
          const qty = relation?.qty;
          // console.log({ qty, amount })
          if (qty > amount) {
            return `Enough (${qty})`;
          } else {
            return `Not enough (${qty})`;
          }
        }
        return "Not relation";
      }
      return "Item and from source required";
    },
    [itemsQuantity?.data]
  );

  const getReceivingQty = useCallback(
    (item_id, from_source, to_source, amount) => {
      if (item_id && from_source && to_source && amount) {
        const item = items?.data?.find(
          (item) => item?.id?.toString() === item_id
        );
        const from = all_source?.data?.find(
          (source) => source?.id?.toString() === from_source
        );
        const to = all_source?.data?.find(
          (source) => source?.id?.toString() === to_source
        );
        // console.log({ item, from, to })
        if (from?.type === to?.type) return amount;
        if (from?.type === "boh" && to?.type === "moh")
          return amount * item?.boh_to_moh_conversion_rate?.moh;
        if (from?.type === "moh" && to?.type === "boh")
          return amount / item?.boh_to_moh_conversion_rate?.moh;
        // if (from?.type === to?.type) return amount
      }
      return 0;
    },
    [all_source?.data]
  );

  const editEntry = (i, e) => {
    // console.log({ [e.target.name]: e.target.value })
    setEntries((prev) => {
      let edited = prev;
      edited[i] = { ...edited[i], [e.target.name]: e.target.value };
      return edited;
    });
  };

  const columns = useMemo(
    () => [
      {
        name: "Item",
        key: "item",
        render: (data, i) => (
          <Drop
            onchange={(e) => {
              editEntry(i, e);
            }}
            name={"inventory_item_id"}
            placeholder={"Select inventory item"}
            options={items?.data}
          />
        ),
      },
      {
        name: "From branch",
        key: "from_branch",
        render: (data, i) => (
          <Drop
            onchange={(e) => {
              editEntry(i, e);
            }}
            name={"from_branch"}
            placeholder={"Select branch"}
            labelKey="name"
            options={branches}
          />
        ),
      },
      {
        name: "From source",
        key: "from_inventory_source",
        render: (data, i) => (
          <Drop
            onchange={(e) => {
              editEntry(i, e);
            }}
            name={"from_inventory_source"}
            placeholder={"Select source"}
            labelKey="name"
            idKey="id"
            options={getSourceByBranch(data?.from_branch)}
          />
        ),
      },
      {
        name: "Sending Qty",
        key: "amount",
        render: (data, i) => (
          <input
            className=""
            type="number"
            name={"amount"}
            onChange={(e) => editEntry(i, e)}
          />
        ),
      },

      {
        name: "To branch",
        key: "to_branch",
        render: (data, i) => (
          <Drop
            onchange={(e) => {
              editEntry(i, e);
            }}
            name={"to_branch"}
            placeholder={"Select branch"}
            labelKey="name"
            options={branches}
          />
        ),
      },
      {
        name: "To source",
        key: "to_inventory_source",
        // render: (data, i) => <Drop onchange={(e) => { editEntry(i, e); }} name={'to_inventory_source'} placeholder={'Select source'} labelKey='label' options={selectOpts(source_to)} />,
        render: (data, i) => (
          <Drop
            onchange={(e) => {
              editEntry(i, e);
            }}
            name={"to_inventory_source"}
            placeholder={"Select source"}
            labelKey="name"
            idKey="id"
            options={getSourceByBranch(data?.to_branch)}
          />
        ),
      },
      {
        name: "Receieving Qty",
        key: "receiving_qty",
        render: (data, i) => (
          <input
            className=""
            type="number"
            value={getReceivingQty(
              data?.inventory_item_id,
              data?.from_inventory_source,
              data?.to_inventory_source,
              data?.amount
            )}
            name={"receiving_qty"}
            onChange={(e) => editEntry(i, e)}
          />
        ),
      },
      {
        name: "Unit",
        key: "unit",
        render: (data, i) => (
          <input
            className="outline-none"
            value={getUnit(data?.inventory_item_id)}
            key={data?.inventory_item_id}
            name={"sending_unit"}
          />
        ),
      },
      {
        name: "Status",
        key: "status",
        render: (data, i) => (
          <input
            className="outline-none"
            value={getStatus(
              data?.inventory_item_id,
              data?.from_inventory_source,
              data?.amount
            )}
            disabled
            key={data?.inventory_item_id}
            name={"status"}
          />
        ),
      },
      {
        name: "Action",
        key: "action",
        render: (data, i) => (
          <Close className="pointer" onClick={() => deleteEntry(data?.id)} />
        ),
      },
    ],
    [items, branches, source_from, source_to, getUnit, entries]
  );

  const submit = async () => {
    setLoading(true);
    // setFormValues()
    try {
      await apiRequest({
        method: "post",
        url: "/operations/inventory-transfer",
        payload: { ...formValues, inventories: entries },
        hasAuth: true,
      });
      toast.success("Transfer successfull");
      navigate("/operations/inventory-transfer");
      dispatch(closeModal());
    } catch (error) {
      console.log(error);
      // throw error
      // const err = error.response.data.data.error.message
      // if (err) toast.error(err)
    } finally {
      setLoading(false);
    }
  };
  // const One = () => (
  //   <>
  //     <Input label='Select Item' type='select' option={selectOpts(items?.data)} placeholder='Select Item' name={'inventory_item_id'} onChange={handleChange} />

  //     <Box className=' w-[100%]' display={'flex'} gap={'16px'}>
  //       <Input label='From Branch' placeholder='select branch' type='select' name='branch_id_from' option={selectOpts(branches)} onChange={handleChange} />
  //       <Input label='To Branch' placeholder='select branch' type='select' name='branch_id_to' option={selectOpts(branches)} onChange={handleChange} />

  //     </Box>
  //     <Box className='w-[100%]' display={'flex'} gap={'16px'}>
  //       <Input label='From source' type='select' option={selectOpts(source_from?.data)} placeholder='From source' name={'from_inventory_source'} onChange={handleChange} />
  //       <Input label='To source' type='select' option={selectOpts(source_to?.data)} placeholder='To source' name={'to_inventory_source'} onChange={handleChange} />
  //     </Box>
  //     {/* <Input label='Sub-Inventory' placeholder='Fridge, Kitchen' type='select' name={'subInventory'} onChange={handleChange} /> */}
  //     <Input icon={unit} label='Amount ' placeholder='Amount' name={'amount'} onChange={handleChange} />
  //     <Input label='Reasons' placeholder='Add Reasons' name={'reason'} type='textarea' onChange={handleChange} />
  //     <Button onClick={() => setCurrent(1)}>Create transfer</Button>
  //   </>
  // )
  const filters = [
    // { name: ' Source', key: 'source', list: invenSource?.data?.map(code => ({ name: code?.name, value: code?.name })) },
    // { name: 'Branches', key: 'branch', list: branches?.map(code => ({ name: code?.name, value: code?.name })) },
    {
      name: "Status",
      key: "status",
      list: ["approved", "pending"].map((btn) => ({ name: btn, value: btn })),
    },
  ];
  return (
    <div className={`p-3 ${styles.reqInvTran}`}>
      <Header
        name="Initiate new Inventory Transfer"
        text={<>New Request Inv Transfer</>}
        isLoading={isLoading}
        buttonOnclick={submit}
        filterComponent={<FilterByList filters={filters} />}
      />
      <Form className={"mb-5 !w-[50%] pl-3"}>
        <Input
          label="Select Date"
          type="date"
          placeholder="Select date"
          name={"date"}
          className={"!w-50"}
          onChange={handleChange}
        />
        <Input
          label="Reason"
          type="textarea"
          placeholder="Reason"
          name={"reason"}
          className={"!w-50"}
          onChange={handleChange}
        />
      </Form>

      <div className={""}>
        <div className={""}>
          {/* <Head /> */}
          {/* {entries[2]?.qty} */}
          <Table
            noPagination
            defaultRowPerpage={100}
            column={columns}
            // url='/operations/purchase-orders'
            data={entries}
            action={false}
            // update={watcher}
          />
          <div className="flex w-full justify-center my-5 ">
            <div
              className="rounded-[100px] font-bold cursor-pointer flex gap-4 py-2 px-5 items-center bg-[#F7F7F7]"
              onClick={addEntry}
            >
              <BiPlus fontWeight={600} /> Add item
            </div>
          </div>
          {/* {<Footer />} */}
        </div>
      </div>
    </div>
  );
};

export default RequestInvTransfer2;
