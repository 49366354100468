import { Box, Typography } from "@mui/material";
// import { FlexText, Text } from 'Components'
import React, { useMemo, useRef } from "react";
import { formatPrice } from "utils";
import styles from "./receipt.module.scss";
import { useQuery } from "react-query";
import { useParams } from "react-router";

import jsPDF from "jspdf";
import { Button } from "Components";
import html2canvas from "html2canvas";
import { getItems, removeCancelled } from "Pages/Waiter/actions";
import { useBranches } from "hooks";

const Receipt = () => {
  const reportTemplateRef = useRef(null);
  const { id } = useParams();
  const { data } = useQuery(`/shop/secure/orders/${id}`);
  const order = useMemo(() => {
    // console.log(data?.data)
    return data?.data;
  }, [data]);

  // const { branches } = useBranches()
  const charges = useMemo(() => {
    const items = removeCancelled(getItems(order));
    const items_prices =
      items.map((item) => parseFloat(item.price * item.quantity)) || [];
    // let Amount = 0
    // if (items_prices.length) Amount = items_prices.reduce((a, b) => a + b)

    // const branch = (branches?.find(branch => branch?.id === order?.branch?.id))

    // const VAT_RATE = (branch?.vat_tax / 100) || 0
    // const STATE_TAX_RATE = (branch?.state_tax / 100) || 0
    // const SERVICE_CHARGE_RATE = (branch?.service_charge / 100) || 0

    // const STATE_TAX = (Amount / (1 + VAT_RATE + STATE_TAX_RATE)) * STATE_TAX_RATE
    // const VAT = (Amount / (1 + VAT_RATE + STATE_TAX_RATE)) * VAT_RATE

    const STATE_TAX = order?.state_tax;
    const VAT = order?.vat;
    const SERVICE_CHARGE = order?.service_charge;
    const SUB_TOTAL = order?.subtotal;
    const TOTAL = order?.total;

    return {
      VAT,
      STATE_TAX,
      SERVICE_CHARGE,
      SUB_TOTAL,
      TOTAL,
    };
  }, [order]);

  const height = useMemo(() => {
    const val = 380;
    const increase_by = 10;
    const items_count = order?.items_count - 1;
    return val + items_count * increase_by;
  }, [order]);

  const downloadPdfDocument = () => {
    const input = reportTemplateRef.current;
    html2canvas(input, {
      scale: 5,
      logging: true,
      letterRendering: 1,
      allowTaint: false,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 5);
      const pdf = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: [100, height],
        putOnlyUsedFonts: true,
      });
      pdf.addImage(imgData, "PNG", 0, 0, 100, height, 0, "NONE");
      // pdf.save(`${order?.order_id}.pdf`);
      window.open(pdf.output("bloburl"), "PRINT");
      // document.getElementById('receipt').print()

      // reportTemplateRef.current.print()
    });
  };

  const Text = ({
    children,
    size,
    weight,
    noWordBreak = false,
    align = "left",
  }) => {
    return (
      <Typography
        sx={{
          fontSize: size,
          fontWeight: weight,
          my: "5px",
          wordBreak: !noWordBreak ? "break-word" : "keep-all",
          textAlign: align,
        }}
      >
        {children}
      </Typography>
    );
  };
  const Line = () => (
    <Box sx={{ border: "1px dashed var(--Color-Neutral-Black, #000)" }}></Box>
  );
  return (
    <>
      <div
        className={`flex items-center h-[95vh] !overflow-y  w-[100vw] ${styles.receipt}`}
      >
        <div
          className="w-[215px] flex flex-col items-center mx-auto pb-2 px-3 text-center"
          ref={reportTemplateRef}
          id="receipt"
        >
          <Box
            component={"img"}
            width={"45px"}
            mb="2"
            height={"45px"}
            src={process.env.PUBLIC_URL + "/logo.png"}
            alt=""
          />
          <Text size="14px" weight="600">
            BeHive
          </Text>
          {order?.branch?.id === 12 && (
            <Text size="13px" align="center">
              37 Razak Balogun St, Surulere 101241, Lagos.
            </Text>
          )}

          {order?.branch?.id !== 12 && (
            <Text align="center" size="13px">
              15C admiralty way lekki phase 1, Lagos.
            </Text>
          )}
          <Text size="13px">SEM@be-hospitality.com</Text>
          <Text size="13px">+2348071873168</Text>
          <br />
          <div className="flex gap-[10px]    flex-col !w-[100%]">
            {/* <FlexText leftText='Customer name' /> */}
            <div className="flex !w-100 justify-between">
              <Text weight="600" size="13px">
                Customer name
              </Text>
              <Text weight="600" size="13px">
                {order?.customer?.full_name}
              </Text>
            </div>
            <div className="flex !w-100 justify-between">
              <Text size="13px">Membership</Text>
              <Text size="13px">{order?.customer?.membership}</Text>
            </div>
            <div className="flex !w-100 justify-between">
              <Text size="13px">Outstanding balance</Text>
              <Text size="13px">{formatPrice(0)}</Text>
            </div>
            <Line />
            <div className="flex !w-100 justify-between">
              <Text weight="600" size="12px">
                Receipt No.
              </Text>
              <Text weight="600" size="12px">
                {order?.invoice?.unique_id}
              </Text>
            </div>
            <div className="flex !w-100 justify-between">
              <Text size="12px">Date:</Text>
              <Text size="12px">{order?.created_at || "N/A"}</Text>
            </div>{" "}
            <div className="flex !w-100 justify-between">
              <Text size="12px">Attendant:</Text>
              <Text size="12px">{order?.creator?.fullname || "N/A"}</Text>
            </div>
            <div className="flex !w-100 justify-between">
              <Text size="12px">Venue </Text>
              <Text size="12px">{order?.venue?.name || "N/A"}</Text>
            </div>
            <div className="flex !w-100 justify-between">
              <Text size="12px">Table </Text>
              <Text size="12px">{order?.table?.name || "N/A"}</Text>
            </div>
            <Line />
            <table className="!w-[100%]" cellSpacing={5}>
              <thead className=" flex flex-col  ">
                <tr className="!w-[100%] flex justify-between ">
                  <th className="!w-[33%] flex flex-start">
                    <Text weight="600" size="12px">
                      Items
                    </Text>
                  </th>
                  <th className="!w-[33%] flex flex-start">
                    <Text noWordBreak weight="600" size="12px">
                      Price(NGN)
                    </Text>
                  </th>
                  <th className="!w-[33%] flex justify-end">
                    <Text weight="600" size="12px">
                      Amount
                    </Text>
                  </th>
                </tr>
              </thead>

              <tbody className="flex flex-col gap-2">
                {removeCancelled(getItems(order))?.map((item) => (
                  <tr className="!w-[100%] flex justify-between ">
                    <td className="items-center  !w-[33%] flex justify-start">
                      <Text size="12px">
                        {item?.menu?.name}
                        {/* The Hive Funky Noodles  */}
                      </Text>
                    </td>
                    <td className="items-center  !w-[33%] flex flex-start">
                      <Text size="12px">
                        {formatPrice(item?.price)} x {item?.quantity}
                      </Text>
                    </td>
                    <td className="items-center  !w-[33%] flex justify-end">
                      <Text size="12px">
                        {formatPrice(item?.price * item?.quantity)}
                      </Text>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Line />
            <div className="flex !w-100 justify-between">
              <Text size="12px"></Text>
              <Text size="12px">Subtotal</Text>
              <Text size="12px" className={"text-right"}>
                {formatPrice(charges.SUB_TOTAL)}
              </Text>
            </div>
            <div className="flex !w-100 justify-between">
              <Text size="12px"></Text>
              <Text size="12px">VAT</Text>
              <Text size="12px">{formatPrice(charges.VAT)}</Text>
            </div>
            <div className="flex !w-100 justify-between">
              <Text size="12px"></Text>
              <Text size="12px">LIRS</Text>
              <Text size="12px">{formatPrice(charges.STATE_TAX)}</Text>
            </div>
            <div className="flex !w-100 justify-between">
              <Text size="12px"></Text>
              <Text size="12px" className={"!w-[50%] border !text-right"}>
                Discount
              </Text>
              <Text size="12px">{formatPrice(order?.discount)}</Text>
            </div>
            <div className="flex !w-100 justify-between">
              <Text size="12px"></Text>
              <Text size="12px">Service charge</Text>
              <Text size="12px">{formatPrice(charges.SERVICE_CHARGE)}</Text>
            </div>
            <Line />
            <div className="flex !w-100 justify-between">
              <Text weight="600" size="14px">
                Total
              </Text>
              <Text weight="600" size="14px">
                {formatPrice(charges.TOTAL)}
              </Text>
            </div>
            {/* <div className='flex flex-col items-center !w-[100%] gap-0'> */}
            <Text align="center" size="12px">
              For bank transfer please pay to{" "}
            </Text>
            <div>
              <Text align="center" weight="600" size="14px">
                Providus Bank
              </Text>
              <Text align="center" weight="600" size="14px">
                5401772129
              </Text>
              <Text align="center" weight="600" size="14px">
                BE GLOBAL NETWORK LIMITED
              </Text>
            </div>
            <div className="flex flex-col gap-0">
              <div
                size="12px"
                className={"font-bold !text-[7px] flex flex-col !gap-0"}
              >
                <div> Open Monday - Friday 8am to 10pm </div>
                <div>Saturday 7am to 9pm</div>
                <div> Sunday 7am to 9pm</div>
              </div>
            </div>
            <Text align="center" size="12px">
              Thank you and come again
            </Text>
            {/* </div> */}
          </div>
        </div>
      </div>
      <div className="w-[200px] mb-3 mx-auto">
        <Button onClick={downloadPdfDocument}>Print</Button>
      </div>
    </>
  );
};

export default Receipt;
