import React, { useMemo } from "react";
import styles from "./Invoivce.module.scss";
import { Header } from "Partial/Header";
import { Button, FlexText, Form, Input, Table } from "Components";
// import { icons } from 'Assets/icons';
// import { BiPlus } from 'react-icons/bi';
// import { apiRequest } from 'Services';
import { useQuery } from "react-query";
// import { path, selectOpts } from 'utils';
import { formatPrice } from "utils/formatPrice";
// import { Close } from '@mui/icons-material';
// import dayjs from 'dayjs';
import { useLocation, useNavigate } from "react-router";

import { ArrowBack } from "@mui/icons-material";
// import { toast } from 'react-toastify';

const InvoiceDetails = () => {
  // const { data: categories } = useQuery(`/admin/service-price-lists/categories`)
  // const status = [{ id: 'Paid', name: 'Paid' }, { id: 'Unpaid', name: 'Unpaid' }]
  // const { data: items } = useQuery('/admin/service-price-lists')
  // const [sub_total, set_Sub__Total] = useState(0)
  // const [totalTax, setTotalTax] = useState(0)
  const location = useLocation();
  const navigate = useNavigate();
  // const { invoice_id } = useParams()
  // /shop/secure/bookings/:bookingType/:bookingId/mark-as-paid
  // const addEntry = () => {
  //     const id = Date.now()
  //     setEntries(prev => [...prev, {
  //         id,
  //         "service_id": null,
  //         "quantity": null,
  //         "unit_price": null,
  //         "tax": 0,
  //         "total": 0,
  //     }])

  // }

  const { pathname } = useLocation();

  const isInWaiter = useMemo(() => {
    return pathname?.includes("waiter");
  }, [pathname]);
  const invoice_id = new URLSearchParams(location.search).get("invoice_id");
  const { data: deets } = useQuery(`/shop/secure/invoices/${invoice_id}`, {
    enabled: Boolean(invoice_id),
  });
  // console.log(deets)
  // const details = useMemo(() => {

  //     const booking_id = new URLSearchParams(location.search).get("booking_id")
  //     const category = new URLSearchParams(location.search).get("category")
  //     return {
  //         booking_id,
  //         category
  //     }
  // }, [location.search])

  // const deleteEntry = (id) => setEntries(prev => prev.filter(x => x.id !== id))
  // const editEntry = (i, e) => {
  //     const name = (e.target.name)
  //     const value = parseInt(e.target.value)

  //     const edit = (name, value) => {
  //         setEntries(prev => {
  //             let edited = prev
  //             edited[i] = { ...edited[i], [name]: value }
  //             return edited
  //         })
  //     }

  //     if (name === 'service_id') {
  //         const res = items?.data?.find(item => item?.id === value)
  //         edit(e.target.name, e.target.value)
  //         edit("tax", res?.tax_rate)
  //         edit("unit_price", res?.price)
  //     } else { edit(e.target.name, e.target.value) }
  //     if (entries[i]?.quantity && entries[i]?.unit_price && entries[i]?.tax) {
  //         edit("total", (entries[i]?.unit_price * parseInt(entries[i]?.quantity)))

  //         // + entries[i]?.tax
  //         const quantity_list = entries.map(entry => parseInt(entry.quantity))
  //         const total_list = entries.map(entry => parseInt(entry.total))
  //         const tax_list = entries.map(entry => parseFloat(entry.tax))
  //         if (total_list.length) set_Sub__Total(total_list.reduce((a, b) => a + b))
  //         if (tax_list.length)
  //             setTotalTax(tax_list.map((tax, i) => {
  //                 // console.log((tax / 100) * total_list[i],tax)
  //                 return (tax / 100) * total_list[i]
  //             }
  //             ).reduce((a, b) => a + b))

  //         }
  //     }

  // const [formValues, setFormValues] = useState({})
  // const handleChange = (e) => setFormValues(prev => ({ ...prev, [e.target.name]: e.target.value }))

  // const save = async () => {

  // try {
  //     await apiRequest({
  //         method: 'post', payload: {
  //             "booking_id": details.booking_id,  // not needed in accounting
  //             // "date": formatDate({ date: formValues?.date, type: 'backenddate' }),
  //             "date": dayjs(formValues?.date).format('YYYY-MM-DD'),
  //             "category": formValues?.category || details.category,
  //             "status": 'Unpaid',
  //             "discount": 0,
  //             "subtotal": sub_total,
  //             "total": parseFloat((sub_total + totalTax).toFixed(2)),
  //             items: entries
  //         }, url: '/shop/secure/invoices', hasAuth: true
  //     })
  //     toast.success('Invoice created successfully')
  //     navigate(-1)
  // } catch (error) {
  //     console.log(error)
  // }
  // }
  const column = useMemo(
    () => [
      {
        name: "Item details",
        key: "name",
      },
      { name: "Quantity", key: "quantity" },
      { name: "Unit price", key: "rate" },
      { name: "Tax", key: "tax" },
      { name: "Total", key: "amount" },
      // { name: 'Action', key: 'action', render: (data, i) => <Close className='cursor-pointer' onClick={() => deleteEntry(data?.id)} /> },
    ],
    []
  );

  const Head = () => (
    <div className={styles.header}>
      <div className="w-[50%] flex flex-col gap-4">
        <Form>
          <Input
            disabled
            label="Booking Number"
            name={"booking_id"}
            placeholder={deets?.data?.booking_id}
          />
          {/* {details.category} */}
          <Input
            disabled
            type="select"
            label="Category"
            name={"category"}
            placeholder={deets?.data?.category}
          />
        </Form>
      </div>
      <div className="w-[50%] flex flex-col gap-4">
        <Form>
          {/* <Input type='select' label='Status' placeholder={'Select status'} onChange={handleChange} name={'status'} value={formValues?.status} option={selectOpts(status)} /> */}
          <Input
            type="text"
            label="Invoice Date"
            disabled
            placeholder={deets?.data?.generated_on}
            name={"date"}
          />
          <Input
            type="text"
            label="Customer name"
            disabled
            placeholder={deets?.data?.customer?.full_name}
            name={"date"}
          />
          <Input
            type="text"
            label="Customer Phone Number"
            disabled
            placeholder={deets?.data?.customer?.phone}
            name={"date"}
          />
        </Form>
      </div>
    </div>
  );

  const Footer = () => (
    <div className={styles.footer}>
      <FlexText leftText="Subtotal" rightText={"₦" + deets?.data?.subtotal} />
      <FlexText leftText="Tax" rightText={"₦" + deets?.data?.tax} />
      <FlexText leftText="VAT" rightText={"₦" + deets?.data?.vat} />
      <FlexText leftText="State Tax" rightText={"₦" + deets?.data?.state_tax} />
      <FlexText
        leftText="Service Charge"
        rightText={"₦" + deets?.data?.service_charge}
      />
      <FlexText leftText="Discount" rightText={"₦" + 0} />
      {/* <FlexText leftText='Tax' rightText={formatPrice(totalTax)} /> */}
      <FlexText
        leftWeight={"600"}
        leftText="Total"
        rightText={formatPrice(deets?.data?.total)}
        rightWeight={"600"}
      />
    </div>
  );
  return (
    <div className={styles.body}>
      <Header
        name={
          <>
            {" "}
            {isInWaiter && (
              <ArrowBack onClick={() => navigate("/waiter/snooker")} />
            )}{" "}
            Invoice Details{" "}
          </>
        }
        //  {deets?.data?.category === "Restaurant (Food Ordering)" && {
        buttonOnclick={() => navigate(`/restaurant-receipt/${invoice_id}`)}
        // }}
        text={
          <>
            {deets?.data?.category === "Restaurant (Food Ordering)" && (
              <>Print receipt </>
            )}
          </>
        }
      />

      <div className={styles.container}>
        <div className={styles.left}>
          {<Head />}
          <Table column={column} data={deets?.data?.items} action={false} />
          {deets?.data?.category === "Snooker" && (
            <Button
              w="fit-content"
              onClick={() => navigate(`/snooker-receipt/${invoice_id}`)}
            >
              Print receipt
            </Button>
          )}

          {/* <div className='flex w-full justify-center my-5 '>
                        <div className='rounded-[100px] font-bold cursor-pointer flex gap-4 py-2 px-5 items-center bg-[#F7F7F7]' onClick={addEntry}>
                            <BiPlus fontWeight={600} /> Add Another Line
                        </div>
                    </div> */}
          {<Footer />}
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;
