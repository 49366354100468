import { Box } from '@mui/material'
import { BtnStack, FlexText, Text } from 'Components'
import ReviewConfirm from 'Pages/Operations/Bookings/components/ReviewConfirm'
import ApproveBtns from 'Pages/Operations/WorklList/ApproveBtns'
import { useBranches } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { changeTitle, openModal } from 'store/slices/modalSlice'


const ApproveInvTransferDetails = ({ submitText, next = () => null, prev = () => null, title = 'Review & Confirm' }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState()
    // const { branches } = useBranches() 
    const { item_id } = useParams()


    const { data: worklist, refetch } = useQuery(`/operations/worklists/${item_id}`, { enabled: Boolean(item_id) })
    // console.log(worklist?.data)
    useEffect(() => {
        dispatch(changeTitle(title))
    }, [dispatch, title])
    const previous = () => {

        prev()
    }
    const nextClick = () => {
        next()
    }
    // const Modal = () => {


    return (
        <Box className={'flex flex-col items-center justify-center h-[100%]   mx-auto'}>
            {/* <ReviewConfirm
                leftText={'Decline'}
                // prev={() => setCurrent(0)}
                submitText={loading ? 'Loading...' : 'Approve'}
            // next={() => submit()}
            > */}


            <div className='w-[90%] gap-[40px] flex flex-col '>
                <Text size='24px' weight='600' align='center'> Approve Inventory Transfer </Text>
                <div className='flex flex-col gap-3 font-[20px]'>
                    <FlexText leftWeight={500} rightWeight={600} leftText={'Item'} rightText={worklist?.data?.workable?.inventory_item?.name} />


                    <FlexText leftWeight={500} rightWeight={600} leftText={'Source branch'} rightText={worklist?.data?.workable?.from_inventory_source?.branch?.name} />
                    <FlexText leftWeight={500} rightWeight={600} leftText={'Source inventory'} rightText={worklist?.data?.workable?.from_inventory_source?.name} />

                    <FlexText leftWeight={500} rightWeight={600} leftText={'Sending quantity'} rightText={`${worklist?.data?.workable?.amount} ${worklist?.data?.workable?.sourceUnit} `} />

                    <FlexText leftWeight={500} rightWeight={600} leftText={'Receiving branch'} rightText={worklist?.data?.workable?.to_inventory_source?.branch?.name} />
                    <FlexText leftWeight={500} rightWeight={600} leftText={'Receiving inventory'} rightText={worklist?.data?.workable?.to_inventory_source?.name} />
                    <FlexText leftWeight={500} rightWeight={600} leftText={'Receiving quantity'} rightText={`${worklist?.data?.workable?.inventory_item?.boh_to_moh_conversion_rate?.moh * worklist?.data?.workable?.amount}  ${worklist?.data?.workable?.destinationUnit} `} />


                    <FlexText leftWeight={500} rightWeight={600} leftText='Reason' rightText={worklist?.data?.workable?.reason} />
                </div>
                <ApproveBtns />
            </div>

            {/* </ReviewConfirm> */}

        </Box>
    )
}

export default ApproveInvTransferDetails;